import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/videos/lfh.gif";
import ProjectImg2 from "../../assets/videos/mtc.gif";
import ProjectImg3 from "../../assets/videos/wp.gif";
import ProjectImg4 from "../../assets/videos/ddg.gif";
import ProjectImg5 from "../../assets/videos/ngc.gif";
import ProjectImg6 from "../../assets/videos/bam.gif";
import ProjectImg7 from "../../assets/videos/llp.gif";
import ProjectImg8 from "../../assets/videos/nac.gif";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Work</h1>
            <p className="font13">
            We’ve built a range of websites, from sleek startup pages to comprehensive e-commerce platforms.
            < br/>
            Each project showcases our dedication to quality and innovation, tailored to meet our clients’ unique needs.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Lizzie Fox Hair"
                action="https://lizziefoxhair.co.uk"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Mill Tyre Centre"
                action="https://milltyresandservice.co.uk/"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Weed-Pro UK"
                action="https://www.weedpro-uk.com/"
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Dan Delaney Golf"
                action="https://dandelaneygolf.com/"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Nigel James Consulting"
                action="https://www.nigeljamesconsulting.co.uk/"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Balloons and More UK" 
                action="https://balloonsandmoreuk.com/"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg7}
                title="Lulu Plumbing"
                action="https://luluplumbing.co.uk/"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg8}
                title="Njordstrom Aircon"
                action="https://njordstromaircon.co.uk/"
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">

        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
